import React from "react";
import Headline from "./Headline";
import Paragraph from "./Paragraph";
import {Colors} from "../Types";

const ImageAndTextDivider: React.FC<Props> = (
  {
    background,
    image,
    header,
    subHeader,
    texts,
    children,
    revertOrder= false
  }
) => {
  const imageOrder = revertOrder ? '2xl:flex-row-reverse flex-row' : '2xl:flex-row';
  return(
    <div className={`flex items-center ${imageOrder} flex-col justify-center bg-${background} 2xl:gap-0 gap-6 py-12`}>
      <div className={`2xl:w-[50vw] w-[100vw] 2xl:min-h-[50vh] min-h-[50vh]`}>
        <div className="flex flex-col 2xl:px-24 2xl:pt-24 pt-6 px-6 items-start gap-6">
          <Headline size="h2" color="quaternary">{header}</Headline>
          <div className="w-[100%] 2xl:max-w-[200px] max-w-[50%] h-0.5">
            <div className="bt-1 h-0.5 solid bg-white"/>
          </div>
          <Headline size="h3" color="tertiary">{subHeader}</Headline>
          <div className="flex flex-col 2xl:max-w-[75%] max-w-100 gap-3">
            {texts.map((text, index)=>{return <Paragraph key={index} color="text-white" size="text-base">{text}</Paragraph>})}
          </div>
          {children}
        </div>
      </div>
      <div className={`bg-image ${image} 2xl:w-[50vw] w-[100vw] 2xl:h-[50vh] h-[50vh]`}></div>
    </div>
  )
};

export default ImageAndTextDivider;

interface Props {
  background: Colors,
  image: "bg-foto-location-1" | "bg-foto-servizi-1",
  header:string,
  subHeader:string,
  texts:string[],
  children?: React.ReactNode,
  revertOrder?: boolean;

}