import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import React from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import {CarouselImages_1} from "../Types";

const Carousel: React.FC<Props> = (
  {
    images
  }
) => {

  return(
    <div className="relative z-0">
      <Swiper
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={30}
        pagination={true}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
      >
        {images.map((img:string, index)=>{
          return <SwiperSlide key={index}>
            <div className={`${img} bg-image  2xl:h-[30vh] h-[30vh]`}/>
          </SwiperSlide>
        })}
      </Swiper>
    </div>
  )
};

export default Carousel;

interface Props {
  images: CarouselImages_1[];
}