import React from "react";
import ImageAndTextDivider from "../Components/ImageAndTextDivider";
import ServiceIcon from "../Components/ServiceIcon";

const LocationAndServices: React.FC = () => {
  return (
    <div className="bg-primary">
      <ImageAndTextDivider
        background={"primary"}
        image={"bg-foto-location-1"}
        header={"Location"}
        subHeader="Autentico Su Misura"
        texts={[
          "Attraverso un'esperienza di relax assoluto tutto inizia non appena chiudi la porta alle tue spalle",
          "I toni salvia delle pareti ti rilassano, la poltrona gialla a pois neri ti strappa un sorriso per la sua grafica fuori contesto e le piante che scorgi ti fanno sentire par te di un ecosistema dove tu e solo tu sei al centro!"
        ]}
        revertOrder={true}
      />
      <ImageAndTextDivider
        background={"primary"}
        image={"bg-foto-servizi-1"}
        header={"Servizi"}
        subHeader="... Ma nel Laboratorio Mr.Trewan cosa si fa?"
        texts={[
          "Semplicemente tu ti rilassi, al resto ci pensiamo noi.",
          "Offriamo 3 livelli di servizio tra cui scegliere, pensati per te e impreziositi dalla nostra passione"
        ]}
        revertOrder={false}
      >
        <div className="flex 2xl:flex-row flex-col align-center 2xl:justify-start justify-center gap-12 w-full">
          <ServiceIcon icon="bg-service-clock" header="Zero Time"/>
          <ServiceIcon icon="bg-service-barber" header="MR Trewan"/>
          <ServiceIcon icon="bg-service-lotus" header="L'Experience"/>
        </div>
      </ImageAndTextDivider>
    </div>
  )
}

export default LocationAndServices;