import React from 'react';
import './App.css';
import Header from "./Layouts/Header";
import Services from "./Layouts/Services";
import ImageDivider from "./Components/ImageDivider";

import LocationAndServices from "./Layouts/LocationAndServices";
import Carousel from "./Components/Carousel";
import Product from "./Layouts/Product";
import NavBar from "./Layouts/NavBar";
import Footer from "./Components/Footer";

function App() {
  const production = false;

  if(production)
    return (
      <div className="App">
        <Header/>
        <Footer/>
      </div>
    )
  return (
    <div className="App">

      <Header/>
      <Services/>
      <ImageDivider
        background={"bg-sfondo-locale"}
        header="Chiudi gli occhi. Respira. Hai tu il controllo."
        texts={[
          "Mr.Trewan nasce dal desiderio di riscatto, dalla travolgente passione e dal costante sacrificio.",
          "Un luogo dove rigenerarsi, riflettere e prepararsi, il tuo obiettivo è li davanti.",
          "FORZA, ENTUSIASMO, RESPONSABILITÀ E DISCIPLINA."
        ]}
      />
      <LocationAndServices/>
      <div className="py-12 w-full h-full bg-quaternary">
        <Carousel images={[
          'bg-foto-servizi-2',
          'bg-foto-servizi-3',
          'bg-foto-servizi-4',
          'bg-foto-servizi-5',
          'bg-foto-servizi-6',
        ]}/>
      </div>
      <Product/>
      <div className="py-12 w-full h-full bg-quaternary">
        <Carousel images={[
          'bg-foto-location-2',
          'bg-foto-location-3',
          'bg-foto-location-4',
          'bg-foto-location-5',
          'bg-foto-location-6',
          'bg-foto-location-7',
        ]}/>
      </div>
      <Footer/>
      <NavBar/>
    </div>
  );
}

export default App;
