import React from "react";
import Headline from "../Components/Headline";

const Product: React.FC = () => {

  return (
    <div className="flex items-center justify-center w-[100vw] py-12 bg-secondary">
      <div className="flex 2xl:flex-row flex-col justify-center items-center 2xl:gap-0 gap-12">
        <div className="flex flex-col justify-center items-center 2xl:w-[50vw] w-[80vw] gap-12">
          <div className="bg-myveg-logo bg-image w-[150px] h-[150px]"></div>
          <div className="2xl:px-48">
            <Headline size="h4" textAlign="text-center" color="primary">
              Ci siamo permessi di selezionare per te solo il meglio che la tecnologia italiana ha da offrire.
            </Headline>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="bg-image bg-myveg-products w-[600px] h-[400px]"/>
        </div>
      </div>

    </div>
  )
}

export default Product;

