import React from "react";
import OutlinedButton from "../Components/OutlinedButton";
const NavBar: React.FC = () => {

  return (
    <nav
      className="bg-black fixed w-full top-0 left-0"
    >
      <div className="2xl:visible invisible 2xl:h-full h-0 px-48">
        <div className="flex items-center justify-between mx-auto p-6 gap-6">
          <div>
            <a href="./"><div className="h-[50px] w-[100px] bg-logo-white-small bg-image"/></a>
          </div>
          <OutlinedButton href={"https://tribeauty.it/MR.TREWAN"} download={false} color="white">Prenota Ora</OutlinedButton>
        </div>
      </div>
      <div className="2xl:invisible visible 2xl:h-0 h-full">
        <div className="flex flex-wrap items-center 2xl:justify-start justify-center mx-auto p-3 gap-6">
          <div>
            <a href="./"><div className="h-[37.5px] w-[75px] bg-logo-white bg-image"/></a>
          </div>
        </div>
      </div>
    </nav>
  )
};

export default NavBar;