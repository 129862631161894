import React from "react";

const Paragraph: React.FC<Props> = (
  {
    color,
    children,
    size = 'text-base',
    textAlign = 'text-left'
  }
) => {
  return <p className={`${color} ${size} ${textAlign}`}>{children}</p>
}

export default Paragraph;

interface Props {
  children: React.ReactNode;
  color: 'text-white' | 'text-primary' | 'text-secondary' | 'text-tertiary' | 'text-quaternary' | 'text-quinary';
  size?: 'text-5xs' | 'text-4xs' | 'text-3xs' | 'text-2xs' | 'text-xs' | 'text-sm' | 'text-base' | 'text-inherit' | string | 'text-xl' | 'text-md' ;
  textAlign?: 'text-left' | 'text-center' | 'text-right';
}