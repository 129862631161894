import React from "react";
import Headline from "./Headline";
import Paragraph from "./Paragraph";

const ImageDivider: React.FC<Props> = (
  {
    background,
    header,
    texts
  }
) => {
  return(
    <div className={`flex items-center justify-center flex-col ${background} bg-image`}>
      <div className="flex items-center justify-center flex-col 2xl:h-[30vh] h-[75vh] v-[100vw] px-6">
        <div className="pb-6"><Headline size="h2" color="quaternary" textAlign="text-center">{header}</Headline></div>
        <div className="flex flex-col 2xl:gap-1 gap-2">{texts.map((text, index)=>{return <Paragraph key={index} color="text-white"  textAlign="text-center" size="text-base">{text}</Paragraph>})}</div>
      </div>

    </div>
  )
};

export default ImageDivider;

interface Props {
  background: "bg-sfondo-locale",
  header:string,
  texts:string[]

}