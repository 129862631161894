import React from "react";
import Headline from "./Headline";
import Paragraph from "./Paragraph";

const Footer: React.FC = () => {
  return(
    <div className="w-full bg-black flex 2xl:flex-row flex-col 2xl:h-[400px] 2xl:p-0 p-6 2xl:gap-0 gap-6">
      <div className="2xl:w-1/3 w-full h-full flex items-center justify-center">
        <a href="./"><div className="h-[200px] w-[200px] bg-logo-white bg-image"/></a>
      </div>
      <div className="2xl:w-1/3 w-full h-full flex flex-col items-start justify-center">
        <div className="pb-6">
          <Headline color="quaternary" size="h3" textAlign="text-left">Contatti</Headline>
        </div>
        <Paragraph color="text-quaternary" size="text-base" textAlign="text-left">MR.TREWAN S.A.S. DI RUGGIERO OMAR E C.</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Via Vittorio Veneto 47 Uggiate Trevano</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Email: mrtrewan@gmail.com</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Telefono: 3497759942 (no prenotazioni)</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">P.IVA: 04082140130</Paragraph>
      </div>
      <div className="2xl:w-1/3 w-full h-full flex flex-col items-start justify-center">
        <div className="pb-6">
          <Headline color="quaternary" size="h3" textAlign="text-left">Orari</Headline>
        </div>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Martedì - Mercoledì - Venerdì</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">9.30 / 13.30 - 14.30 / 19.30</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Giovedì: 14.00 / 21.00</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Sabato: 8.00 / 18.00</Paragraph>
        <Paragraph color="text-white" size="text-base" textAlign="text-left">Domenica e Lunedì chiuso</Paragraph>
      </div>
    </div>
  )
};

export default Footer;