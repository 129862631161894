import React from "react";
import Headline from "./Headline";
import {ServiceImages} from "../Types";

const ServiceIcon: React.FC<Props> = (
  {
    header,
    icon
  }
) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`bg-image ${icon} w-[100px] h-[100px]`}/>
      <Headline size="h4" color="quaternary">{header}</Headline>
    </div>
  )
}

export default ServiceIcon;

interface Props {
  header: string,
  icon:ServiceImages
}