import React from "react";
import {ButtonColors} from "../Types";
const OutlinedButton: React.FC<Props> = (
  {
    download,
    children,
    color,
    href,
    downloadName
  }
) => {
  if(download){
    return(
      <a
        href={href}
        download={downloadName}
        target="_blank"
        rel="noreferrer"
      >
        <button className={`${colors[color]} border-2 py-2 px-4 w-[200px]`}>{children}</button>
      </a>
    )
  }
  else{
    return(
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <button className={`${colors[color]} border-2 py-2 px-4 w-[200px]`}>{children}</button>
      </a>
    )
  }

};

export default OutlinedButton;

interface Props {
  download: boolean;
  color: ButtonColors;
  children?: React.ReactNode;
  href?: string;
  downloadName?: string;
}

const colors = Object.freeze<Record<'white', string>>({
  white: 'text-white bg-transparent border-white hover:border-quaternary hover:text-quaternary'
});